import { CommonModule } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

@Component({
  selector: 'vk-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  size = input<number>(24);
  hasTransition = input(false, { transform: booleanAttribute });
  name = input.required<string>();
  customHover = input<string | undefined>();

  isHover: boolean = false;
  link = computed(() => this.getLink(this.name()));
  customLink = computed(() => {
    const customHover = this.customHover();
    return customHover ? this.getLink(customHover) : '';
  });

  getLink(name: string): string {
    return `/assets/images/icons/${name}.svg#${name}`;
  }
}
