<svg
  [style.width.px]="size()"
  [style.height.px]="size()"
  [class.on-hover]="isHover && !!customHover()"
  (mouseover)="isHover = true"
  (mouseout)="isHover = false"
>
  <use class="icon" [attr.href]="link()" [class.transition]="hasTransition()"></use>
  @if (customLink()) {
    <use class="custom-hover" [attr.href]="customLink()" [class.transition]="hasTransition()"></use>
  }
</svg>
